




import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: { type: String },
  components: { AVehicleIcon: () => import('@/components/atoms/a-vehicle-icon.vue') },

  // setup(props) {
  //   const name = computed(() => {
  //     switch (props.type) {
  //       case 'ambulance': return 'Ambulans'
  //       case 'bus-articulated': return 'Autobus przegubowy'
  //       case 'bus-double-decker': return 'Autobus piętrowy'
  //       case 'bus-school': return 'Autobus szkolny'
  //       case 'bus': return 'Autobus'
  //       case 'convertible': return 'Kabriolet'
  //       case 'estate': return 'Kombi'
  //       case 'hatchback': return 'Hatchback'
  //       case 'limousine': return 'Limuzyna'
  //       case 'pickup': return 'Pickup'
  //       case 'sedan': return 'Sedan'
  //       case 'sports': return 'Sportowy'
  //       case 'dump-truck': return 'Wywrotka'
  //       case 'fire-truck': return 'Wóz strażacki'
  //       case 'forklift': return 'Wózek widłowy'
  //       case 'moped': return 'Motorower'
  //       case 'motorbike': return 'Motocykl'
  //       case 'rickshaw': return 'Riksza'
  //       case 'rv-truck': return 'Kamper'
  //       case 'tanker-truck': return 'Cysterna'
  //       case 'tow-truck': return 'Holownik/Laweta'
  //       case 'tractor': return 'Traktor'
  //       case 'truck': return 'Ciężarówka'
  //       case 'van-passenger': return 'Bus'
  //       case 'van-utility': return 'Dostawczak'
  //       case 'bicycle': return 'Rower'
  //       case 'bicycle-basket': return 'Rower z koszykiem'
  //       case 'bicycle-penny-farthing': return 'Bicykl'
  //       case 'golf-cart': return 'Wózek golfowy'
  //       case 'gondola': return 'Gondola'
  //       case 'horse': return 'Koń'
  //       case 'scooter': return 'Hulajnoga'
  //       case 'unicycle': return 'Monocykl'
  //       case 'sail-boat': return 'Łódź'
  //       case 'airplane': return 'Samolot'
  //       case 'helicopter': return 'Helikopter'
  //       case 'rocket': return 'Rakieta'
  //       default: return 'sedan'
  //     }
  //   })

  //   return { name }
  // }
})
